import React, { useState, useEffect, useRef } from 'react';
import { ReactMic } from 'react-mic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faPaperPlane, faPaperclip, faFileImage, faFileAlt, faVideo } from '@fortawesome/free-solid-svg-icons'; // Add faVideo
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const MessageSend = ({ handleInputChange, newMessage, sendMessage, emojiSend, ImageSend, DocumentSend, AudioSend, VideoSend, currentfriend }) => { // Add VideoSend
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showFileOptions, setShowFileOptions] = useState(false);

  const fileOptionsRef = useRef(null);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    if (recording) {
      const id = setInterval(() => {
        setTimer(prev => prev + 1);
      }, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    return () => clearInterval(intervalId);
  }, [recording]);

  const toggleRecording = () => {
    setRecording(prev => !prev);
    if (!recording) {
      setTimer(0);
    }
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blob);
  };

  const onData = (recordedBlob) => {
    console.log('chunk of real-time data is: ', recordedBlob);
  };

  const sendAudio = () => {
    if (audioBlob) {
      const data = {
        audio: audioBlob,
        receiverId: currentfriend._id,
      };
      console.log("Sending audio:", data);
      AudioSend(data);
      setAudioBlob(null);
      setTimer(0);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (newMessage.trim() !== '') {
        handleSendMessage();
      }
    }
  };

  const handleSendMessage = () => {
    sendMessage('');
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const addEmoji = (emoji) => {
    emojiSend(emoji.native);
    setShowEmojiPicker(false);
  };

  const toggleFileOptions = () => {
    setShowFileOptions(!showFileOptions);
  };

  const handleClickOutside = (event) => {
    if (fileOptionsRef.current && !fileOptionsRef.current.contains(event.target)) {
      setShowFileOptions(false);
    }
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showFileOptions || showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFileOptions, showEmojiPicker]);

  const handleFileOptionClick = (option) => {
    if (option === 'photo') {
      document.getElementById('pic').click();
    } else if (option === 'document') {
      document.getElementById('doc').click();
    } else if (option === 'video') {
      document.getElementById('vid').click(); // Add for video
    }
    setShowFileOptions(false);
  };

  return (
    <div className='message-send-section'>
      <div className='file hover-image' onClick={toggleFileOptions}>
        <div className='file-upload'>
          <FontAwesomeIcon icon={faPaperclip} className='upload-icon' />
          {showFileOptions && (
            <div className='file-options' ref={fileOptionsRef}>
              <div className='dropdown-item' onClick={() => handleFileOptionClick('photo')}>
                <FontAwesomeIcon icon={faFileImage} /> Photo
              </div>
              <div className='dropdown-item' onClick={() => handleFileOptionClick('document')}>
                <FontAwesomeIcon icon={faFileAlt} /> Document
              </div>
              <div className='dropdown-item' onClick={() => handleFileOptionClick('video')}> {/* Add Video option */}
                <FontAwesomeIcon icon={faVideo} /> Video
              </div>
            </div>
          )}
          <input onChange={ImageSend} type="file" id="pic" className='form-control' accept='image/*' style={{ display: 'none' }} />
          <input onChange={DocumentSend} type="file" id="doc" className='form-control' style={{ display: 'none' }} />
          <input onChange={VideoSend} type="file" id="vid" className='form-control' accept='video/*' style={{ display: 'none' }} /> {/* Add input for video */}
        </div>
      </div>

      <div className='message-type'>
        <input
          type="text"
          className='textarea'
          role='textbox'
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          name='message'
          id='message'
          placeholder='Write a message...'
          value={newMessage}
        />
        <div className='emoji-picker' ref={emojiPickerRef}>
          <i className='material-symbols-outlined' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>Mood</i>
          {showEmojiPicker && (
            <div className='emoji-dropdown'>
              <Picker onSelect={addEmoji} />
            </div>
          )}
        </div>
      </div>

      <div className='recording-section'>
        <ReactMic
          record={recording}
          className="sound-wave"
          onStop={onStop}
          onData={onData}
          strokeColor="transparent"
          backgroundColor="transparent"
        />
        {!newMessage.trim() ? (
          <FontAwesomeIcon
            icon={recording ? faMicrophoneSlash : faMicrophone}
            onClick={toggleRecording}
            className={`record-icon ${recording ? 'recording' : ''}`}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPaperPlane}
            onClick={handleSendMessage}
            className='send-icon'
          />
        )}
        {recording && <div className='timer'>{formatTime(timer)}</div>}
        {audioBlob && (
          <FontAwesomeIcon
            icon={faPaperPlane}
            onClick={sendAudio}
            className='send-icon'
          />
        )}
      </div>
    </div>
  );
};

export default MessageSend;
