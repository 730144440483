import axios from 'axios';
import {FRIEND_GET_SUCCESS,MESSAGE_GET_SUCCESS,MESSAGE_SEND_SUCCESS,THEME_GET_SUCCESS,THEME_SET_SUCCESS,UPLOAD_AUDIO_FAILURE,UPLOAD_AUDIO_SUCCESS} from "../types/messengerType";


export const VideoMessageSend = (data) => async (dispatch) => {
     const config = {
          headers: {
               'Content-Type': 'multipart/form-data'
          }
     }
     
     try {
       const response = await axios.post('/api/messenger/video-message-send', data,config);
       dispatch({
         type: MESSAGE_SEND_SUCCESS,
         payload: { message: response.data.message }
       });
     } catch (error) {
       console.log(error.response.data);
     }
   };






export const DocumentMessageSend = (data) => async (dispatch) => {
     const config = {
          headers: {
               'Content-Type': 'multipart/form-data'
          }
     }
     try {
       const response = await axios.post('/api/messenger/document-message-send', data,config);
       dispatch({
         type: MESSAGE_SEND_SUCCESS,
         payload: {
           message: response.data.message
         }
       });
     } catch (error) {
       console.log(error.response.data);
     }
   };
   

export const uploadAudio = (formData) => async (dispatch) => {
     const config = {
          headers: {
               'Content-Type': 'multipart/form-data'
          }
     }
     try {
         const response = await axios.post('/api/messenger/upload', formData, config);
         console.log('response',response);
         dispatch({ type: 'UPLOAD_AUDIO_SUCCESS', payload: {
          message: response.data.message
         } });
     } catch (error) {
         dispatch({ type: 'UPLOAD_AUDIO_FAIL', payload: error.message });
     }
 };


export const getFriends = () => async(dispatch) => {

     try{
          const response = await axios.get('/api/messenger/get-friends');
           dispatch({
                type: FRIEND_GET_SUCCESS,
                payload : {
                     friends : response.data.friends
                }
           })

     }catch (error){
          console.log(error.response.data);
     }
}

export const updateProfile = (data) => async(dispatch) => {
     const config = {
          headers: {
               'Content-Type': 'multipart/form-data'
          }
     }
     try{
          const response = await axios.post('/api/messenger/update-profile',data,config);
          dispatch({
               type: 'PROFILE_UPDATE_SUCCESS',
               payload : {
                    user : response.data.user,
                    token : response.data.token
               }
          })
     }catch (error){
          console.log(error);
     }
}



export const generateKeyFiles = (userId) => async(dispatch) => {

     try{

          const response = await axios.post('/api/keys/generatekeys',{userId});
          dispatch({
               type: 'KEY_GENERATE_SUCCESS',
               payload : {
                    publicKey : response.data.public_key
               }
          })

     }catch (error){
          console.log(error.response.data);
     }
}

export const decryptData = (encryptedData, userId, type) => async (dispatch) => {
     let messageText;
     
     
     try {
       // Extraire et stocker le texte du message s'il existe
       if (encryptedData && encryptedData.message && encryptedData.message.text !== undefined) {
         messageText = encryptedData.message.text;
         delete encryptedData.message.text;
         console.log('messageText', messageText);
       }
   
     
   
       console.log('before axios', encryptedData);
       const response = await axios.post('/api/keys/decrypt', { encrypted: encryptedData, userId });
   
       if (response.data && response.data.decrypted && response.data.decrypted.message) {
         // Traiter le texte décrypté
         if (response.data.decrypted.message.text !== undefined) {
           const valuesArray = Object.values(response.data.decrypted.message.text);
           const decryptedText = valuesArray.join('"');
           response.data.decrypted.message.text = decryptedText;
         }
       }
   
       // Réintégrer le texte du message s'il existe
       if (messageText !== undefined) {
         response.data.decrypted.message.text = messageText;
       }
   
      
   
       dispatch({
         type: 'DECRYPT_SUCCESS',
         payload: {
           decrypted: response.data.decrypted,
           type: type
         }
       });
     } catch (error) {
       console.log(error);
     }
   }
   





export const getUnseenMessageNumber = (friendId,myId) => async(dispatch) => {
     try{
          const response = await axios.get(`/api/messenger/get-unseen-message-number/${friendId}`,{myId : myId});
          return response.data.unseenMessageNumber;
     }catch (error){
          console.log(error.response.data);
     }
}

export const setLastSeen = (userId) => async(dispatch) => {
     const friendId = userId;
     try{
          const response = await axios.post('/api/messenger/set-last-seen',{id : friendId});
          dispatch({
               type: 'USER_DISCONNECTED',
               payload : {
                    friendId : friendId
               }
          })
     }catch (error){
     }
}

export const updateMsg = (msgId,newMsg) => async(dispatch)=> {

     try{
          const response = await axios.post('/api/messenger/update-message',{msgId,newMsg});
          dispatch({
               type: 'MESSAGE_UPDATE_SUCCESS',
               payload : {
                    msgId : msgId,
                    newMsg : newMsg
               }
          })
     }
     catch (error){
          console.log(error.response.data);
     }
};

export const deleteMessage = (msg) => async(dispatch) => {
     try{
          const response = await axios.post('/api/messenger/delete-message',{
               _id : msg._id
          })
          dispatch({
               type: 'MESSAGE_DELETE_SUCCESS',
               payload : {
                     msg : msg
               }
          })
     }catch (error){
          console.log(error);
     }
}


export const clearAlert = () => async(dispatch) => {
     dispatch({
          type: 'ALERT_CLEAR'
     })
}

export const sendInvitation = (email) => async (dispatch) => {
     try {
       const response = await axios.post('/api/messenger/send-invitation', { email });
       // Check the response status and display appropriate alert
       if (response.status === 200) {
         dispatch({ type: "ALERT_SUCCESS", payload: response.data.success });
       } else {
         dispatch({ type: "ALERT_ERROR", payload: response.data.error });
       }
     } catch (error) {
       // If there's an error, display an alert with the error message
       dispatch({ type: "ALERT_ERROR", payload: error.response.data.error });
     }
   };

export const getUsers = (friendIds,myId) => async(dispatch) => {
     try{
          const response = await axios.get('/api/messenger/get-all-users', {
               params: {
                    friendIds: friendIds,
                    myId : myId
                }
          });
           dispatch({
                type: 'GET_USERS_SUCCESS',
                payload : {
                     users : response.data.users
                }
           })

     }catch (error){
          console.log(error.response.data);
     }
}

export const addFriend = (friend,myId) => async(dispatch) => {
     console.log('friend',friend)
     const friendId = friend.fndInfo._id;
     try{
          const response = await axios.post('/api/messenger/add-friend',{friendId,myId});
          dispatch({
               type: 'ADD_FRIEND_SUCCESS',
               payload : {
                    friend : friend
               }
          })
     }catch (error){
          console.log(error.response.data);
     }
}
export const sendAudioMessage = (data) => async (dispatch) => {
  console.log("sendAudioMessage called with data:", data);
  if (data.get('audio')) {
    const formData = new FormData();
    formData.append('audio', data.get('audio'));
    formData.append('senderName', data.get('senderName'));
    formData.append('receiverId', data.get('receiverId'));

    // Log the FormData content
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      console.log("Sending audio message to server...");
      const response = await axios.post('/api/messenger/send-audio', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("Server response:", response.data);
      dispatch({
        type: MESSAGE_SEND_SUCCESS,
        payload: {
          message: response.data.message,
          audio: response.data.audio,
          forwarded: data.get('forwarded')
        }
      });
    } catch (error) {
      console.error("Error sending audio message:", error);
      dispatch({
        type: UPLOAD_AUDIO_FAILURE,
        payload: error.response ? error.response.data : error.message
      });
    }
  } else {
    console.log("No audio data found");
  }
};
   
export const sendMessageToBackend = (data) => async(dispatch) => {
     console.log('data',data);
    try{
     const response = await axios.post('/api/messenger/send-message',data);
     console.log('response',response)
     dispatch({
          type : MESSAGE_SEND_SUCCESS,
          payload : {
               message : response.data.message,
               forwarded : data.forwarded,
               
          }
     })
    }catch (error){
     console.log(error.response.data);
     console.log('error',error)
    }
}


export const getMessage = (id) => {
     return async(dispatch) => {
          try{
               const response = await axios.get(`/api/messenger/get-message/${id}`);
              dispatch({
                   type : MESSAGE_GET_SUCCESS,
                   payload : {
                    message : response.data.message
                   }
              })
          }catch (error){
               console.log(error.response.data)
          }
     }
}
export const AudioMessageSend = (data) => async(dispatch) => {
     try {
       const response = await axios.post('/api/messenger/audio-message-send', data);
       dispatch({
         type: MESSAGE_SEND_SUCCESS,
         payload: {
           message: response.data.message
         }
       });
     } catch (error) {
       console.log(error.response.data);
     }
   };
export const ImageMessageSend = (data) => async(dispatch)=>{

     try{
          const response = await axios.post('/api/messenger/image-message-send',data);
          dispatch({
               type: MESSAGE_SEND_SUCCESS,
               payload : {
                    message : response.data.message
               }
          })
     }catch (error){
          console.log(error.response.data);

     }

}


   

export const seenMessage = (msg) => async(dispatch)=> {
     try{
          const response = await axios.post('/api/messenger/seen-message',msg);
     }catch (error){
          console.log(error.response.message)

     }
}



export const updateMessage = (msg) => async(dispatch)=> {
     try{
          const response = await axios.post('/api/messenger/delivared-message',msg);
     }catch (error){
          console.log(error.response.message)

     }
}


export const getTheme = () => async(dispatch) => {

     const theme = localStorage.getItem('theme');
     dispatch({
          type: "THEME_GET_SUCCESS",
          payload : {
               theme : theme? theme : 'white'
          }
     })

}

export const getAllMessages = (friendId) => async (dispatch) => {
     try {
       const response = await axios.get(`/api/messenger/get-all-messages/${friendId}`);
       dispatch({
         type: MESSAGE_GET_SUCCESS,
         payload: {
           messages: response.data.message
         }
       });
     } catch (error) {
       console.log(error.response.data);
     }
   };

  
   
   // Vous pouvez également ajouter une action pour récupérer tous les messages, en fonction de votre besoin
   export const getAllMessage = (friendId) => async (dispatch) => {
     try {
       const response = await axios.get(`/api/messenger/get-all-messages/${friendId}`);
       dispatch({
         type: MESSAGE_GET_SUCCESS,
         payload: {
           messages: response.data.message
         }
       });
     } catch (error) {
       console.log(error.response.data);
     }
   };


export const themeSet = (theme) => async(dispatch) => {

     localStorage.setItem('theme',theme);
     dispatch({
          type: "THEME_SET_SUCCESS",
          payload : {
               theme : theme
          }
     })

}
